import React from 'react';
import Global from '../svg/global.svg';
import Snail from '../svg/snail.svg';
import Tree from '../svg/tree.svg';

function ValueBloc() {
  return (
    <section className="p-8 bg-primary lg:p-12">
      <div className="pt-16 text-center text-white">
        <p>solving for</p>
        <h3 className="py-2 text-5xl font-extrabold">fast fashion</h3>
        <p
          className="max-w-5xl pt-8 text-white opacity-80"
          style={{ margin: '0 auto' }}
        >
          it's a pretty big problem; these are our steps in the right direction
          towards solving it. In general, it is a toxic industry; creating high
          waste, low life span clothing to fuel the growth of profit margins. We
          are no angels. We’ve all bought cheap, throw away clothes before but
          we've grown and wiser now.
          <br />
          <br />
          <b>it's time for a change.</b>
          <br />
          <br />
          the ways in which we are go in the right direction:
        </p>
      </div>
      <div className="flex flex-col items-baseline w-full text-white md:flex-row">
        <div className="flex flex-col items-center w-full px-8 py-16 lg:w-2/6 md:py-24 md:px-4">
          <Snail alt="Global plants" />
          <div className="px-6 text-center">
            <h2 className="pt-8 pb-2 text-2xl font-bold">slow fashion</h2>
            <p className="text-sm opacity-80">
              we do not mass produce. This is to ensure a high level of quality
              and eradicate any unnecessary waste from the get-go.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center w-full px-8 py-16 text-white lg:w-2/6 md:py-24 md:px-4">
          <Global alt="Global plants" />
          <div className="px-6 text-center">
            <h2 className="pt-8 pb-2 text-2xl font-bold">sustainable</h2>
            <p className="text-sm opacity-80">
              we use sustainable materials as much we can + combining that with
              a low waste production process to reduce our footprint and waste.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center w-full px-8 py-16 text-white lg:w-2/6 md:py-24 md:px-4">
          <Tree alt="stop sign with waste bins" />
          <div className="px-6 text-center">
            <h2 className="pt-8 pb-2 text-2xl font-bold">carbon offset</h2>
            <p className="text-sm opacity-80">
              with every order, we plant a tree thanks to you. This is to
              further our steps to a carbon neutral company.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ValueBloc;
